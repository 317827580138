import React,{useState} from 'react'

import '../../styles/technology.css'

export default function Technology() {
    const [toggle, setToggle] = useState(false)
    const [toggle1, setToggle1] = useState(false)
    const [toggle2, setToggle2] = useState(false)
    const handleonClick=()=>{
        setToggle(!toggle)
        setToggle1(false)
        setToggle2(false)
    }
    const handleonClick1=()=>{
        setToggle(false)
        setToggle1(!toggle1)
        setToggle2(false)
    }
    const handleonClick2=()=>{
        setToggle(false)
        setToggle1(false)
        setToggle2(!toggle2)
    }
    return (
        <section className="technology" id="technology">
            <div className="container">
                <div className="section-heading">
					<h1>Technologies</h1>
					<h6>View additional technologies</h6>
				</div>
                <div className="technologies">
                    <div className="front-end-tech" onClick={handleonClick} data-aos="fade-in" data-aos-delay="300">
                        <h1><i className="fas fa-laptop-code"></i> {"   "}Front-End{"      "}<i className="fas fa-sort-down"></i></h1>           
                        {toggle && (
                            <ul className="tech-list" data-aos="fade-down" data-aos-duration="500">
                            <li>HTML5</li>
                            <li>CSS3</li>
                            <li>BOOTSTRAP 4</li>
                            <li>JQUERY</li>
                            <li>JAVASCRIPT ES5/ES6</li>
                            <li>ANIMATE.CSS</li>
                            <li>AOS</li>
                        </ul>
                        )}
                    </div>
                    <div className="back-end-tech" onClick={handleonClick1} data-aos="fade-in" data-aos-delay="600">
                        <h1><i className="fas fa-wrench"></i>{"   "}Back-End{"      "}<i className="fas fa-sort-down"></i></h1>
                        {toggle1 && (
                            <ul className="tech-list" data-aos="fade-down" data-aos-duration="500">
                            <li>PASSPORT</li>
                            <li>CORS</li>
                            <li>JSONWEBTOKEN</li>
                            <li>AXIOS</li>
                            <li>MOCHA/CHAI</li>
                            <li>MUTLER</li>
                            <li>MVC</li>
                        </ul>
                        )}
                    </div>
                    <div className="other-tech" onClick={handleonClick2} data-aos="fade-in" data-aos-delay="900">
                        <h1><i className="fas fa-desktop"></i>{"   "}Other {"      "}<i className="fas fa-sort-down"></i></h1>
                        {toggle2 && (
                            <ul className="tech-list" data-aos="fade-down" data-aos-duration="500">
                            <li>GITHUB</li>
                            <li>HEROKU</li>
                            <li>NETLIFY</li>
                            <li>NAMECHEAP</li>
                            <li>AGILE DEVELOPMENT</li>
                            <li>ADOBE PHOTOSHOP</li>
                            <li>ADOBE ILLUSTRATOR</li>
                        </ul>
                        )}
                    </div>
                </div>
            </div>
        </section>
    )
}
