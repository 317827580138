import React from 'react'

import '../../styles/about.css'
import profilePic from '../../images/profile_image.jpg'
import resume from '../../resume/bibash-kc-resume.pdf'

export default function About() {
    return (
        <section className="about" id="about">
            <div className="container">
                <div className="profile-img" data-aos="fade-right" data-aos-delay="300">
                    <img src={profilePic} alt="" />
                </div>
                <div className="about-details" data-aos="fade-left" data-aos-delay="600">
				<div className="about-heading">
					<h1>About</h1>
					<h6>Myself</h6>
				</div>
				<p>Hello, my name is Bibash. I am a MERN stack developer living in Woodside, NY. 
					I completed my bachelor's degree in Electrical Engineering from the State 
					University of New York at Oswego. My expertise is in the front end. I have a 
					diverse set of skills, ranging from design, to HTML+ CSS+ javaScript+ React+ redux, 
					all the way to Node.js. With every line of code, I strive to make the web a fun place. 
					I have a desire for designing flawless user experiences and writing clean, elegant, 
					and efficient code.   
				</p>
				<div className="social-media">
					<ul className="nav-list nav-list-custom">
						<li>
							<a href={resume} alt="" className="icon-link" download="bibash_kc" >
								<span>Resume</span>
								<i className="fas fa-cloud-download-alt"></i>
							</a>
						</li>
						<li>
							<a href="https://www.facebook.com/" className="icon-link" alt="facebook">
								<i className="fab fa-facebook-square"></i>
							</a>
						</li>
						<li>
							<a href="https://github.com/Bibashkc/" className="icon-link" alt="github">
								<i className="fab fa-github-square"></i>
							</a>
						</li>
					</ul>
				</div>
			</div>
            </div>
        </section>
    )
}
