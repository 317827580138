import React,{useState} from 'react'
import emailjs from 'emailjs-com'
import Recaptcha from 'react-recaptcha'

import '../../styles/contact.css'

export default function Contact() {
		// sitekey="6Ldwn8wZAAAAAC-Tk84uRWjvpRbd0r0iC49B3vU1" localhost
	const [loaded, setLoaded] = useState(false)
	const [verify, setVerify] = useState(false)
	const [confirm, setConfirm] = useState(false)
	const [toggle, setToggle] = useState({success:false, error:false})

	const handleonSubmit=e=>{
		e.preventDefault()
		if(verify && loaded){
			emailjs.sendForm('gmail', 'template_q9w34rg', e.target, 'user_Z7JjY3g2lDj623gVjSznw')
			.then((result) => {
				setToggle({...toggle, success:true})
				setVerify(false)
				setTimeout(() => {
					setToggle({...toggle, success:false})
				}, 3000);
			}, (error) => {
				setToggle({...toggle, error:true})
				setVerify(false)
				setTimeout(() => {
					setToggle({...toggle, error:false})
				}, 3000);
			});
			e.target.reset()
			window.grecaptcha.reset();
		} else {
			setConfirm(true)
			setTimeout(() => {
				setConfirm(false)
			}, 3000);
		}
	}
	const recaptchaLoaded=()=>{
		setLoaded(true)
	}
	const verifyCallback=(response)=>{
		if(response){
			setVerify(true)
		}
	}
    return (
        <section className="contact" id="contact">
            <div className="container">
                <div className="section-heading">
					<h1>Contact</h1>
					<h6>Let's Work together</h6>
				</div>
				
				<form onSubmit={e=>handleonSubmit(e)} data-aos="fade-up" data-aos-delay="300">
					{toggle.success && (<div className="alert alert-success" role="alert">
										Message sent successully.
									</div>)}
					{toggle.error && (<div className="alert alert-danger" role="alert">
										Something went wrong.
									</div>)}
					{confirm && (<div className="alert alert-danger" role="alert">
						Please confirm you are human.
					</div>)}
					<label htmlFor="name">Name:</label>
					<input type="text" name="name" id="name" placeholder="Enter your name..." required />

					<label htmlFor="email">Email:</label>
					<input type="text" name="email" id="email" placeholder="Enter your email..." required /> 

					<label htmlFor="subject">Services:</label>
					<select name="subject" id="subject">
                        <option>To Hire</option>
						<option>Web Design</option>
						<option>Web Development</option>
						<option>Web Design/Development</option>
					</select>
					<label htmlFor="message">Message:</label>
					<textarea name="message" id="message" cols="10" rows="10" required></textarea>
					<Recaptcha
							sitekey="6Ld2nswZAAAAAETXoVa7kB2TlzL2mjaTtq4T_qdz"
							render="explicit"
							verifyCallback={verifyCallback}
							onloadCallback={recaptchaLoaded}
							theme="dark"
					/>
					<input type="submit"  className="submit-button" name="" value="Submit" />
				</form>
            </div>
        </section>
    )
}
