import React,{useEffect} from 'react'
import Particles from 'react-particles-js'
import Aos from 'aos'
import 'aos/dist/aos.css'
import '../../styles/landing.css'


export default function Landing() {
    useEffect(()=>{
        Aos.init({duration:2000})
    },[])
    return (
        <div className="landing" style={{height:"100vh"}}>
            <Particles 
                params={{
                    "particles": {
                        "number": {
                          "value": 40,
                          "density": {
                            "enable": true,
                            "value_area": 800
                          }
                        },
                        "color": {
                          "value": "#ffffff"
                        },
                        "shape": {
                          "type": "circle",
                          "stroke": {
                            "width": 0,
                            "color": "#000000"
                          },
                          "polygon": {
                            "nb_sides": 5
                          }
                        },
                        "opacity": {
                          "value": 0.5,
                          "random": false,
                          "anim": {
                            "enable": false,
                            "speed": 1,
                            "opacity_min": 0.1,
                            "sync": false
                          }
                        },
                        "size": {
                          "value": 5,
                          "random": true,
                          "anim": {
                            "enable": false,
                            "speed": 10,
                            "size_min": 0.1,
                            "sync": false
                          }
                        },
                        "line_linked": {
                          "enable": true,
                          "distance": 250,
                          "color": "#ffffff",
                          "opacity": 0.4,
                          "width": 1
                        },
                        "move": {
                          "enable": true,
                          "speed": 1,
                          "direction": "none",
                          "random": false,
                          "straight": false,
                          "out_mode": "out",
                          "attract": {
                            "enable": false,
                            "rotateX": 600,
                            "rotateY": 1200
                          }
                        }
                      },
                      "interactivity": {
                        "detect_on": "canvas",
                        "events": {
                          "onhover": {
                            "enable": true,
                            "mode": "repulse"
                          },
                          "onclick": {
                            "enable": true,
                            "mode": "push"
                          },
                          "resize": true
                        },
                        "modes": {
                          "grab": {
                            "distance": 400,
                            "line_linked": {
                              "opacity": 1
                            }
                          },
                          "bubble": {
                            "distance": 400,
                            "size": 40,
                            "duration": 2,
                            "opacity": 8,
                            "speed": 3
                          },
                          "repulse": {
                            "distance": 200
                          },
                          "push": {
                            "particles_nb": 4
                          },
                          "remove": {
                            "particles_nb": 2
                          }
                        }
                      },
                      "retina_detect": true,
                      "config_demo": {
                        "hide_card": false,
                        "background_color": "#b61924",
                        "background_image": "",
                        "background_position": "50% 50%",
                        "background_repeat": "no-repeat",
                        "background_size": "cover"
                      }
                }}
                height="100vh"
            /> 
            <div className="name-info">
                <h1>Bibash KC</h1>
                {/* <p>Senior Frontend Developer | Electrical Engineer</p> */}
                <p>Senior Front Developer @ 
                    <a href="https://mindera.com/" 
                    alt="mindera link" 
                    rel="noopener noreferrer"
                    target="_blank">Mindera
                    </a>
                  </p>
                <p>Working on:
                  <a href="http://visitnepal.today/" 
                  className="name-info-link" 
                  alt="visitnepal page" 
                  target="_blank" 
                  rel="noopener noreferrer"> visitnepal.today</a></p>
            </div>
        </div>
    )
}
