import React from 'react'

import '../../styles/skill.css'

export default function Skill() {
    return (
        <section className="languages" id="languages">
            <div className="container">
                <div className="section-heading">
					<h1>Skills</h1>
					<h6>View some of my skills</h6>
				</div>
                <div className="my-skills iphone-skills">
					<div className="skill iphone" data-aos="fade-in" data-aos-delay="300">
						<div className="icon-container icon-container-skill">
                            <i className="icon-mongodb"></i> 
						</div>
						<h1>Mongodb</h1>
					</div>
					<div className="skill iphone" data-aos="fade-in" data-aos-delay="600">
						<div className="icon-container icon-container-skill">
							<h1>Express</h1>
						</div>
						<h1>Express</h1>
					</div>
					<div className="skill iphone" data-aos="fade-in" data-aos-delay="900">
						<div className="icon-container icon-container-skill">
                        <i className="icon-reactjs"></i>
						</div>
						<h1>React</h1>
					</div>
                    <div className="skill iphone" data-aos="fade-in" data-aos-delay="1000">
						<div className="icon-container icon-container-skill">
                            <i className="icon-nodejs"></i>
						</div>
						<h1>NodeJS</h1>
					</div>
				</div>
            </div>
        </section>
    )
}
