import React from 'react';
import './App.css';
import './index.css'

import Landing from './components/Layout/Landing'
import Navbar from './components/Layout/Navbar'
import About from './components/Layout/About'
import Service from './components/Layout/Service'
import Portfolio from './components/Layout/Portfolio'
import Skill from './components/Layout/Skill'
import Technology from './components/Layout/Technology'
import Experience from './components/Layout/Experience'
import Contact from './components/Layout/Contact'
import Footer from './components/Layout/Footer'

function App() {
  return (
    <div className="app">
      <Navbar />
      <Landing />
      <About />
      <Service />
      <Portfolio />
      <Skill />
      <Technology />
      <Experience />
      <Contact />
      <Footer />
    </div>
  );
}

export default App;
