import React from 'react'

import '../../styles/footer.css'

export default function Footer() {
    const handleonClick=()=>{
        window.scrollTo({
            top:0,
            behavior:"smooth"
        })
    }
    return (
        <footer className="copyright">
            <div className="up" id="up" onClick={handleonClick}>
				<i className="fas fa-chevron-up"></i>
			</div>
            <p>&copy; {new Date().getFullYear()} Bibash Kc</p>
        </footer>
    )
}
