import React from 'react'

import '../../styles/experience.css'

export default function Experience() {
    return (
        <section className="experience" id="experience">
            <div className="container">
                <div className="section-heading">
					<h1>Work Experience</h1>
					<h6>Past and Current jobs</h6>
				</div>
                <div className="timeline" data-aos="fade-down" data-aos-delay="300">
                        <ul>
                            <li className="date" data-date="2021 - ">
                                <h1>Mindera</h1>
                                <div>
                                    <h2>Senior Frontend Developer</h2>
                                    <h2>Environment: HTML5, CSS3, JavaScript, React.JS,Redux, BootStrap, Node.JS, Express, Mongoose, MongoDB</h2>
                                </div>
                                <br />
                            </li>
                            <li className="date" data-date="2020 - 2021">
                                <h1>Freelancer</h1>
                                <div>
                                    <h2>Full Stack Developer</h2>
                                    <h2>Environment: HTML5, CSS3, JavaScript, React.JS,Redux, BootStrap, Node.JS, Express, Mongoose, MongoDB</h2>
                                </div>
                                <br />
                            </li>
                            <li className="date" data-date="2017- 2019">
                                <h1>Prabhu Group, Inc</h1>
                                <div>
                                    <h2>Front End/UI Developer</h2>
                                    <h2>Environment: HTML5 CSS3, JavaScript, React.JS, Redux, BootStrap, Node.JS, REST, JSON</h2>
                                </div>
                                <br />
                            </li>
                            <li className="date" data-date="2017 - 2017">
                                <h1>Mila International</h1>
                                <div>
                                    <h2>Internship</h2>
                                    <h2>Arduino Mega, Andriod, Bluetooth, DC Stepper Motor, Microwave sensor</h2>
                                </div>
                                <br />
                            </li>
                        </ul>
				    </div>
            </div>
        </section>
    )
}
