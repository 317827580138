import React,{useState} from 'react'

import '../../styles/portfolio.css'
import woodsideCafe from '../../images/woodside_cafe.jpg'
import nynfc from '../../images/nynfc.jpg'
import ecommerce from '../../images/ecommerce.jpg'
import mingmaFdtn from '../../images/mingmafdtn.jpg'
import visitNepal from '../../images/visit-nepal.jpg'
import syangjaSamaj from '../../images/syangja-samaj.jpg'


export default function Portfolio() {

	const [toggle, updateToggle] = useState(false)


	const style = {
		opacity:".7"
	}
	const styleP = {
		fontWeight:"bold",
		fontSize:"1.7"
	}
    return (
        <section className="portfolio" id="portfolio">
            <div className="container">
				<div className="section-heading">
					<h1>Portfolio</h1>
					<h6>View some of my recent work</h6>
				</div>
				<div className="portfolio-item">
					<div className="portfolio-img has-margin-right" data-aos="fade-right" data-aos-delay="300">
						<img src={woodsideCafe} alt="" />
					</div>
					<div className="portfolio-description" data-aos="fade-left" data-aos-delay="600">
						<h6>Web Development</h6>
						<h1>Business Website</h1>
						<p>
							<span style={styleP}>Type:</span> <span style={style}>Dyanamic</span>
						</p>
						<p><span style={styleP}>Technology:</span> <span style={style}>HTML5, CSS3, Bootstrap 4, javaScript, React, Redux</span></p>
						<p><span style={styleP}>Key:</span> <span style={style}>Web Token, Login, User Authentication, Forms, Product Profile, Image Upload, Parallex Effect</span></p>
						<p><span style={styleP}>Platform: </span><span style={style}>Multi, Responsive</span></p>
						<p><span style={styleP}>Status:</span><span style={style}> Completed</span></p>
						<a href="https://woodsidecafeny.com/" className="cta">View Details</a>
					</div>
				</div>
				<div className="portfolio-item">
					<div className="portfolio-description has-margin-right" data-aos="fade-right" data-aos-delay="900">
						<h6>Web Development</h6>
						<h1>Sport Website</h1>
						<p><span style={styleP}>Type:</span> <span style={style}>Dyanamic</span></p>
						<p><span style={styleP}>Technology:</span> <span style={style}>HTML5, CSS3, Bootstrap 4, javaScript, React, Redux, Express, Mongoose, MongoDB</span></p>
						<p><span style={styleP}>Key:</span> <span style={style}>Web Token, Login, User Authentication, Forms, User Profile, Admin</span></p>
						<p><span style={styleP}>Platform:</span> <span style={style}>Multi, Responsive</span></p>
						<p><span style={styleP}>Status:</span><span style={style}> In progress</span></p>
						<a href="https://nynfcnew.netlify.app/" className="cta">View Details</a>
					</div>
					<div className="portfolio-img" data-aos="fade-left" data-aos-delay="1200">
						<img src={nynfc} alt="" />
					</div>
				</div>
				<div className="portfolio-item">
					<div className="portfolio-img has-margin-right" data-aos="fade-right" data-aos-delay="1500">
						<img src={mingmaFdtn}alt="" />
					</div>
					<div className="portfolio-description" data-aos="fade-left" data-aos-delay="1800">
						<h6>Web Development</h6>
						<h1>Nonprofit Website</h1>
						<p><span style={styleP}>Type:</span> <span style={style}> Static</span></p>
						<p><span style={styleP}>Technology:</span> <span style={style}>HTML5, CSS3, Bootstrap 4, javaScript, React, Redux</span></p>
						<p><span style={styleP}>Key:</span> <span style={style}>Paypal Integration</span></p>
						<p><span style={styleP}>Platform: </span><span style={style}>Multi, Responsive</span></p>
						<p><span style={styleP}>Status:</span><span style={style}> Completed</span></p>
						<a href="https://www.mingmafoundation.org/" className="cta">View Details</a>
					</div>
				</div>
				{toggle && (
					<div>
						<div className="portfolio-item">
							<div className="portfolio-description has-margin-right" data-aos="fade-right" data-aos-delay="100">
								<h6>Web Development</h6>
								<h1>Ecommerce Website</h1>
								<p><span style={styleP}>Type:</span> <span style={style}> Dyanamic</span></p>
								<p><span style={styleP}>Technology:</span> <span style={style}>HTML5, CSS3, Bootstrap 4, javaScript, React, Redux, Express, Mongoose,Mutler, MongoDB</span></p>
								<p><span style={styleP}>Key:</span> <span style={style}>Web Token, Login, User Authentication, Forms, Product Profile, Image Upload, Cart</span></p>
								<p><span style={styleP}>Platform:</span><span style={style}> Multi, Responsive</span></p>
								<p><span style={styleP}>Status:</span><span style={style}> Completed</span></p>
								<a href="https://blooming-sierra-21187.herokuapp.com/" className="cta">View Details</a>
							</div>
						<div className="portfolio-img" data-aos="fade-left" data-aos-delay="100">
							<img src={ecommerce} alt="" />
						</div>
					</div>
					<div className="portfolio-item">
						<div className="portfolio-img has-margin-right" data-aos="fade-right" data-aos-delay="600">
							<img src={visitNepal}alt="" />
						</div>
						<div className="portfolio-description" data-aos="fade-left" data-aos-delay="600">
							<h6>Web Development</h6>
							<h1>Blog Website</h1>
							<p><span style={styleP}>Type:</span> <span style={style}> Dyanamic</span></p>
							<p><span style={styleP}>Technology:</span> <span style={style}>HTML5, CSS3, Bootstrap 4, javaScript, React, Redux, Express, Mongoose,Mutler, MongoDB</span></p>
							<p><span style={styleP}>Key:</span> <span style={style}>Web Token, Login, User Authentication, Forms, Product Profile, Image Upload</span></p>
							<p><span style={styleP}>Platform:</span><span style={style}> Multi, Responsive</span></p>
							<p><span style={styleP}>Status:</span><span style={style}> In progress</span></p>
							<a href="https://newvisitnepal.netlify.app/" className="cta">View Details</a>
						</div>
					</div>

					<div className="portfolio-item">
							<div className="portfolio-description has-margin-right" data-aos="fade-right" data-aos-delay="900">
								<h6>Web Development</h6>
								<h1>Syangja Society Website</h1>
								<p><span style={styleP}>Type:</span> <span style={style}> Static</span></p>
								<p><span style={styleP}>Technology:</span> <span style={style}>React,HTML5, CSS3, Bootstrap 4, javaScript</span></p>
								<p><span style={styleP}>Key:</span> <span style={style}>Paypal Integration</span></p>
								<p><span style={styleP}>Platform:</span><span style={style}> Multi, Responsive</span></p>
								<p><span style={styleP}>Status:</span><span style={style}> Completed</span></p>
								<a href="https://syangjasamaj.netlify.app/" className="cta">View Details</a>
							</div>
						<div className="portfolio-img" data-aos="fade-left" data-aos-delay="900">
							<img src={syangjaSamaj} alt="" />
						</div>
					</div>
					
				</div>
				)}
				<div className="text-center view-more-div">
					<button className="text-center view-more-btn" onClick={()=>updateToggle(!toggle)}>View More</button>
				</div>
			</div>
        </section>
    )
}
