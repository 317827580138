import React from 'react'
import '../../styles/navbar.css'

export default function Navbar() {
    const handleonClick = (e) => {
        document.querySelector(`.menu-toggler`).classList.toggle("open")
        document.querySelector('.top-nav').classList.toggle('open')
    }
    return (
        <header className="container-fluid navbar">
            <div className="menu-toggler" onClick={(e)=>handleonClick(e)}>
                <div className="bar half start"></div>
                <div className="bar"></div>
                <div className="bar half end"></div>
            </div>

            <div className="top-nav">
                <ul className="nav-list">
                    <li>
                        <a href="/" className="nav-link" onClick={(e)=>handleonClick(e)}>Home</a>
                    </li>
                    <li>
                        <a href="#about" className="nav-link"  onClick={(e)=>handleonClick(e)}>About</a>
                    </li>
                    <li>
                        <a href="#services" className="nav-link" onClick={(e)=>handleonClick(e)}>Services</a>
                    </li>
                    <li>
                        <a href="#portfolio" className="nav-link" onClick={(e)=>handleonClick(e)}>Portfolio</a>
                    </li>
                    <li>
                        <a href="#languages" className="nav-link" onClick={(e)=>handleonClick(e)}>Skills</a>
                    </li>
                    <li>
                        <a href="#experience" className="nav-link" onClick={(e)=>handleonClick(e)}>Experience</a>
                    </li>
                    <li>
                        <a href="#contact" className="nav-link" onClick={(e)=>handleonClick(e)}>Contact</a>
                    </li>
                </ul>
            </div>
        </header>
    )
}
